import { ButtonHTMLAttributes } from "react";
import ReactLoading from "react-loading";
import * as S from "./styles";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  varitant?: "ghost";
  width?: string;
  isLoading?: boolean;
}

export function Button({
  varitant,
  title,
  width,
  isLoading,
  ...rest
}: ButtonProps) {
  return (
    <S.ButtonContainer
      varitant={varitant}
      width={width}
      disabled={isLoading}
      {...rest}
    >
      {isLoading ? (
        <ReactLoading height={25} width={25} type={"spin"} color="#ffffff" />
      ) : (
        title
      )}
    </S.ButtonContainer>
  );
}
