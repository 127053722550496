import styled, { css } from "styled-components";

type ContainerProp = {
  isResponsive: boolean;
};

export const Container = styled.section<ContainerProp>`
  width: 100%;
  width: calc(100vw - 7rem - var(--sideBarWidth));
  overflow-x: auto;
  overflow-y: hidden;

  ${(props) =>
    props.isResponsive &&
    css`
      width: calc(100vw - 7rem - var(--sideBarWidthResponsive));
    `};

  table {
    width: 100%;
    background-color: ${(props) => props.theme.newColors["white-500"]};
    border: 1px solid ${(props) => props.theme.newColors["gray-500"]};
    border-radius: 4px;
    border-spacing: 0;
    border-bottom: none;

    th,
    td {
      text-align: left;
      font-size: ${(props) => props.theme.fontSize.XXXS};
      font-weight: ${(props) => props.theme.fontWeight.Semibold};
      color: ${(props) => props.theme.newColors["gray-900"]};
      padding: 0.25rem 0;
      padding-left: 1rem;

      border-bottom: 1px solid ${(props) => props.theme.newColors["gray-500"]};
    }

    th:first-child,
    td:first-child {
      border-right: 1px solid ${(props) => props.theme.newColors["gray-500"]};
    }

    th:last-child,
    td:last-child {
      padding-right: 1rem;
    }

    th:last-child,
    td:last-child {
      border-right: none;
    }

    td {
      font-weight: ${(props) => props.theme.fontWeight.Regular};
    }

    th {
      padding: 0;
    }
  }
`;

export const ThStyled = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;
  padding-left: 1rem;
`;

export const SortAlign = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  div {
    display: flex;
    align-items: center;
  }
`;

type ResizeProps = {
  isResizing: boolean;
};

export const Resize = styled.div<ResizeProps>`
  background-color: ${(props) => props.theme.newColors["gray-500"]};
  height: 32px;
  width: 4px;
  opacity: 0;

  &:hover {
    cursor: ew-resize;
    opacity: 1;
  }
`;
