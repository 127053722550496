import styled, { css } from "styled-components";

interface ButtonContainerProps {
  width?: string;
  varitant?: "ghost";
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.colors["blue-600"]};
  color: ${(props) => props.theme.newColors["white-300"]};
  border: none;
  border-radius: 0.5rem;
  font-family: ${(props) => props.theme.fontFamily.Roboto};

  padding: 0.5rem 0.75rem;

  height: 2rem;
  width: 7.5rem;

  &:hover {
    background-color: ${(props) => props.theme.newColors["blue-700"]};
    transition: all 0.2s;
  }

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${(props) =>
    props.varitant === "ghost" &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.newColors["blue-500"]};
      color: ${(props) => props.theme.newColors["blue-700"]};

      &:hover {
        background-color: transparent;
      }
    `}
`;
