import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  padding: 1.5rem 3rem;
  padding-bottom: 0;
`;

export const HeaderContainer = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 2rem;

  gap: 2rem;
`;

export const FilterTitle = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Regular};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  color: ${(props) => props.theme.colors.black};

  .required {
    color: ${(props) => props.theme.colors["red-200"]};
    margin-left: 0.375rem; 
  }
`;

export const SelectInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.375rem;
`;
