import * as Dialog from "@radix-ui/react-dialog";
import styled, { css } from "styled-components";

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
`;

type ModalContentProps = {
  custombuttons?: string;
};

export const ModalContent = styled(Dialog.Content)<ModalContentProps>`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  border-radius: 1rem;
  background: ${(props) => props.theme.colors.white};

  width: 58rem;
  max-height: 90%;
  overflow-y: auto;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${(props) =>
    props.custombuttons === "true" &&
    css`
      padding-bottom: 0;
    `}
`;

export const Content = styled.div`
  padding-bottom: 1.5rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    display: flex;
    align-items: center;

    border: none;
    background-color: transparent;

    color: ${(props) => props.theme.newColors.black};
  }
`;

export const ModalTitle = styled(Dialog.Title)`
  font-size: ${(props) => props.theme.fontSize.XS};
  color: ${(props) => props.theme.newColors["gray-900"]};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
`;

export const ModalSubTitle = styled.span`
  color: ${(props) => props.theme.newColors["gray-900"]};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
`;

export const ModalText = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  color: ${(props) => props.theme.newColors["gray-900"]};
`;

export const Separator = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors["gray-100"]};
  margin: 1.5rem 0;
`;

export const ModalDescription = styled(Dialog.Description)``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
`;
