export const newColors = {
  "black-900": "#030303",
  black: "#000000",

  "blue-500": "#0063F9",
  "blue-700": "#0056C9",

  "gray-200": "#f1f1f1",
  "gray-300": "#EBEBE4",
  "gray-500": "#D3D3D3",
  "gray-600": "#A1A1A1",
  "gray-700": "#6D6D6D",
  "gray-900": "#323232",
  "gray-950": "#2E2E2E",

  "red-500": "#AF2A1A",

  "white-300": "#F9FAFA",
  "white-500": "#F8F8F8",
  white: "#ffffff",
} as const;
