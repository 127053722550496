export enum OrderStatus {
  NEW = "NEW",
  STARTED = "STARTED",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  UNPROCESSABLE = "UNPROCESSABLE",
  CANCELLED = "CANCELLED",
  PARTIALLY_PROCESSED = "PARTIALLY_PROCESSED",

  //Sale
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
  ARCHIVED = "ARCHIVED",
}

export const OrderStatusConverter = {
  NEW: "New",
  STARTED: "Started",
  ERROR: "Error",
  SUCCESS: "Success",
  UNPROCESSABLE: "Unprocessable",
  CANCELLED: "Canceled",
  PARTIALLY_PROCESSED: "Partially Processed",

  //Sale
  PENDING: "Pending",
  IN_PROGRESS: "In Progress",
  FINISHED: "Finished",
  ARCHIVED: "Archived",
};

export enum OrderItemProcessStatus {
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  PICKED = "PICKED",
  PICKING = "PICKING",
  PENDING = "PENDING",
  SHIPPED = "SHIPPED",
  CANCELLED = "CANCELLED",

  //ERRORS
  NEW = "NEW",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export const OrderItemProcessStatusConverter = {
  PARTIALLY_REFUNDED: "Partially Refunded",
  PICKED: "Picked",
  PICKING: "Picking",
  PENDING: "Pending",
  SHIPPED: "Shipped",
  CANCELLED: "Canceled",

  //ERRORS
  NEW: "New",
  ERROR: "Error",
  SUCCESS: "Success",
};

export enum PickingItemStatus {
  PICKED = "PICKED",
  PICKING = "PICKING",
  PENDING = "PENDING",
  SHIPPED = "SHIPPED",
  CANCELLED = "CANCELLED",
}

export const PickingItemStatusConverter = {
  PARTIALLY_REFUNDED: "Partially Refunded",
  PICKED: "Picked",
  PICKING: "Picking",
  PENDING: "Pending",
  SHIPPED: "Shipped",
  CANCELLED: "Cancelled",
};
