import styled, { css } from "styled-components";
import { OrderItemProcessStatus } from "../../../../../../enums/Orders";

export const ErrorMessageContainer = styled.div`
  margin-top: 1.5rem;
  padding: 8px 8px 8px 32px;
  width: 38rem;

  border-radius: ${(props) => props.theme.borderRadius.SM};
  border: 1px solid ${(props) => props.theme.colors["red-450"]};
  border-left: 8px solid ${(props) => props.theme.colors["red-450"]};
`;

export const ErrorMessage = styled.span`
  line-height: 24px;
`;

export const Separator = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors["gray-400"]};
`;

export const TableContainer = styled.div`
  max-height: 28rem;
  overflow: auto;
  overflow-x: hidden;

  margin-top: 1rem;

  table {
    padding-right: 0.5rem;
  }

  thead {
    background-color: ${(props) => props.theme.colors["gray-200"]};
    position: sticky;
    top: 0;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors["gray-700"]};
  }
`;
