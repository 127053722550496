import styled from "styled-components";

export const OrdersDetailsContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;

  padding: 1.5rem 3rem;
`;

export const LoadingContainer = styled.div`
  height: calc(100vh - 21rem);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OrderButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.colors["blue-600"]};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  color: ${(props) => props.theme.newColors["white-300"]};
  border: none;
  border-radius: 0.5rem;

  padding: 0.5rem 0.75rem;

  height: 2rem;
  width: 9rem;

  &:hover {
    background-color: ${(props) => props.theme.newColors["blue-700"]};
    transition: all 0.2s;
  }
`;
