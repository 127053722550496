import { HTMLProps, forwardRef } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import * as S from "./styles";

interface SelectInputProps
  extends Omit<HTMLProps<HTMLSelectElement>, "options" | "title"> {
  onChange: (e: any) => void;
  variant?:
    | "form"
    | "pagination"
    | "selectStores"
    | "small"
    | "exploreItems"
    | "profile"
    | "roles"
    | "fullWidth"
    | "resized";
  disabled?: boolean;
  error?: string;
  title?: string;
  placeholder?: string;
  options: {
    id: string;
    value: string;
    label: string;
  }[];
  width?: string;
}

export const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>(
  (
    {
      title,
      onChange,
      variant,
      disabled,
      error,
      placeholder,
      options,
      width,
      ...rest
    },
    ref,
  ) => {
    const optionsWithoutDefaultValue = options.filter(
      (option) => option.label.toLowerCase() !== placeholder,
    );

    return (
      <S.SelectInputContainer variant={variant}>
        <S.ErrorAlignContainer>
          {title && <span>{title}</span>}
          {error && (
            <div className="error_div">
              <FaExclamationTriangle />
              <label>{error}</label>
            </div>
          )}
        </S.ErrorAlignContainer>
        <S.SelectInputWrapper
          variant={variant}
          disabled={disabled}
          width={width}
        >
          <select disabled={disabled} ref={ref} onChange={onChange} {...rest}>
            {placeholder && (
              <option disabled={disabled} defaultValue={placeholder} value="">
                {placeholder}
              </option>
            )}
            {optionsWithoutDefaultValue.map((option) => (
              <option key={option.id} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {variant === "resized" && (
            <MdKeyboardArrowDown className="arrow-icon" />
          )}
        </S.SelectInputWrapper>
      </S.SelectInputContainer>
    );
  },
);

SelectInput.displayName = "SelectInput";
