import { useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { ConfirmationModal } from "../../../../../components/ConfirmationModal";
import { PageHeader } from "../../../../../components/Headers/PageHeader";
import { NotFound } from "../../../../../components/NotFound";
import { OrderStatus } from "../../../../../enums/Orders";
import { useAuth } from "../../../../../hooks/auth";
import { permissions } from "../../../../../shared/Permissions";
import { OrderDetailsComplete } from "../components/OrderDetailsComplete";
import { OrderDetailsError } from "../components/OrderDetailsError";
import * as S from "./styles";
import { useContextSelector } from "use-context-selector";
import { OrdersContext } from "../../../../../contexts/OrdersContext";
import { Modal } from "../../../../../components/New/Modal";
import { RefundModal } from "../components/RefundModal";
import { CancelModal } from "../components/CancelModal";

export function OrderDetails() {
  const { orderId } = useParams();
  const { colors } = useTheme();

  const {
    fetchOrderDetails,
    loading,
    ordersSyncErrorLoading,
    retryImport,
    orderDetails,
  } = useContextSelector(OrdersContext, (context) => {
    return context;
  });

  const { user } = useAuth();

  const navigate = useNavigate();

  const hasTrackNumber = orderDetails?.sales?.some(
    (item) => item.shipmentData?.trackingNumber,
  );

  const handleForceSync = async () => {
    try {
      await retryImport(orderDetails.id, true);
      await fetchOrderDetails(orderDetails.id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, []);

  return (
    <S.OrdersDetailsContainer>
      <PageHeader
        title={`Order ID: #${orderDetails?.orderReferenceNumber}`}
        description="Filter and search for information and create a complete report"
        customBreadcrumbString="Order Details"
        customButton={() => {}}
        orderDetailsStatus={
          loading.orderDetails ? "loading" : orderDetails.processStatus
        }
      >
        <Modal
          title="Refund Order"
          modalContent={<RefundModal />}
          customButtons
        >
          <S.OrderButton
            disabled={
              !orderDetails.hasAvailableRefunds &&
              orderDetails.sourceMarketplace !== "Incycle Shopify"
            }
          >
            Refund Order
          </S.OrderButton>
        </Modal>

        <Modal
          title="Cancel Order"
          modalContent={<CancelModal />}
          customButtons
        >
          <S.OrderButton
            // disabled={
            //   hasTrackNumber || orderDetails.processStatus === "CANCELLED"
            // }
            disabled
          >
            Cancel Order
          </S.OrderButton>
        </Modal>

        <ConfirmationModal
          title={"Are you sure you want to force sync this order?"}
          description={
            "This action cannot be undone and you may duplicate the sale in Lightspeed!"
          }
          variant="delete"
          confirmFunction={async () => handleForceSync()}
        >
          <S.OrderButton
            disabled={
              ordersSyncErrorLoading.loading ||
              orderDetails.forceReprocess ||
              !user.user.permissions.includes(permissions.report.orders.edit) ||
              orderDetails.processStatus !== OrderStatus.SUCCESS
            }
          >
            {ordersSyncErrorLoading.loading ? (
              <ReactLoading height={24} width={24} type={"spin"} color="#fff" />
            ) : (
              "Force Sync All"
            )}
          </S.OrderButton>
        </ConfirmationModal>
      </PageHeader>

      {loading.orderDetails ? (
        <S.LoadingContainer>
          <ReactLoading
            type="spin"
            height={60}
            width={60}
            color={colors["gray-800"]}
          />
        </S.LoadingContainer>
      ) : orderDetails.id ? (
        orderDetails?.sales?.length ? (
          <OrderDetailsComplete />
        ) : (
          <OrderDetailsError />
        )
      ) : (
        !loading.orderDetails && (
          <NotFound
            title="Order Error"
            buttonTitle="Orders"
            onClick={() => navigate("/report/orders")}
          />
        )
      )}
    </S.OrdersDetailsContainer>
  );
}
