import { ToastPosition, toast } from "react-toastify";

export const toastify = (
  type: "success" | "error",
  message: string,
  position: ToastPosition,
) => {
  toast[type](message, {
    position,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
