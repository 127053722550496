import * as Accordion from "@radix-ui/react-accordion";
import styled from "styled-components";

export const AccordionContainer = styled(Accordion.Root)`
  margin: 1.5rem 0;
`;

export const AccordionButtonContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  gap: 0.5rem;

  cursor: pointer;
`;

export const AccordionTitle = styled.span`
  font-size: ${(props) => props.theme.fontSize.XS};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  font-weight: ${(props) => props.theme.fontWeight.Medium};
  color: ${(props) => props.theme.colors["black-925"]};
`;

export const AccordionContent = styled.div`
  border: 1px solid red;
`;
