import { ButtonHTMLAttributes } from "react";
import * as S from "./styles";

interface CircleCheckboxProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  checked: boolean;
  label?: string;
  onClick: () => void;
}

export const CircleCheckbox = ({
  checked,
  label,
  onClick,
  ...rest
}: CircleCheckboxProps) => {
  return (
    <S.CheckboxContainer onClick={onClick} {...rest}>
      <S.HiddenCheckbox
        id="circle-checkbox"
        onChange={() => {}}
        checked={checked}
      />
      <S.StyledLabel htmlFor="circle-checkbox" />

      <S.CircleCheckboxLabel>{label}</S.CircleCheckboxLabel>
    </S.CheckboxContainer>
  );
};
