import { useEffect, useState } from "react";
import { Button } from "../../../../../../components/New/Button";
import { CircleCheckbox } from "../../../../../../components/New/CircleCheckbox";
import {
  ModalSubTitle,
  ModalText,
} from "../../../../../../components/New/Modal/styles";
import * as S from "./styles";
import { Table } from "../../../../../../components/New/Table";
import { ColumnDef } from "@tanstack/react-table";
import { IncreaseInput } from "../../../../../../components/New/IncreaseInput";
import { wrapperRequests } from "../../../../../../services/api";
import { routesURL } from "../../../../../../services/routesUrl";
import { useContextSelector } from "use-context-selector";
import { OrdersContext } from "../../../../../../contexts/OrdersContext";
import { TableCheckbox } from "../../../../../../components/New/Table/components/TableCheckbox";
import { SelectInput } from "../../../../../../components/SelectInput";
import {
  RefundCondition,
  RefundPercentage,
  RefundReason,
  RefundStockLocation,
} from "./constants";
import * as Dialog from "@radix-ui/react-dialog";
import {
  OrderRefundItem,
  RefundOrderInitialValue,
  RefundOrderSale,
  RefundSaleItems,
} from "../../../../../../@types/Orders";
import { toastify } from "../../../../../../shared/Toastify";
import { useNavigate, useParams } from "react-router-dom";
import { newColors } from "../../../../../../styles/themes/default/New/foundations/colors";
import ReactLoading from "react-loading";

export function RefundModal() {
  const [checked, setChecked] = useState(false);
  const [entireRefundItems, setEntireRefundItems] = useState<RefundOrderSale[]>(
    [],
  );
  const [loading, setLoading] = useState(true);
  const [initialRefundItems, setInitialRefundItems] = useState<
    RefundOrderInitialValue[]
  >([]);
  const [refundItems, setRefundItems] = useState<RefundSaleItems[]>([]);
  const [refundType, setRefundType] = useState<"entire" | "individual">(
    "entire",
  );

  const { orderId } = useParams();
  const navigate = useNavigate();

  const { orderDetails } = useContextSelector(OrdersContext, (context) => {
    return context;
  });

  const refundModalColumns: ColumnDef<OrderRefundItem>[] = [
    {
      accessorKey: "name",
      header: "Product",
      size: 300,
      cell: (props) => (
        <S.CheckBoxRow
          title={props.getValue() ? (props.getValue() as string) : ""}
          onClick={() => addOrRemoveItem(props.row.original)}
        >
          <TableCheckbox
            checked={refundItems.some(
              (items) => items.saleLineID === props.row.original.saleLineID,
            )}
            onClick={() => setChecked(!checked)}
          />
          {props.getValue()}
        </S.CheckBoxRow>
      ),
    },
    {
      accessorKey: "quantityToRefund",
      header: "Qty",
      size: 100,
      cell: (props) => (
        <IncreaseInput
          disabled={
            !refundItems.some(
              (items) => items.saleLineID === props.row.original.saleLineID,
            )
          }
          handleIncreaseValue={() =>
            handleChangeQuantity(
              props.row.original.saleLineID,
              "increase",
              props.row.original.quantityToRefund,
              refundItems.find(
                (item) => item.saleLineID === props.row.original.saleLineID,
              )?.quantity || 1,
            )
          }
          handleDecreaseValue={() =>
            handleChangeQuantity(
              props.row.original.saleLineID,
              "decrease",
              props.row.original.quantityToRefund,
              refundItems.find(
                (item) => item.saleLineID === props.row.original.saleLineID,
              )?.quantity || 1,
            )
          }
          maxValue={props.row.original.quantityToRefund}
          value={
            refundItems.find(
              (item) => item.saleLineID === props.row.original.saleLineID,
            )?.quantity || 1
          }
        />
      ),
    },
    {
      accessorKey: "reason",
      header: "Reason",
      size: 20,
      cell: (props) => (
        <SelectInput
          disabled={
            !refundItems.some(
              (items) => items.saleLineID === props.row.original.saleLineID,
            )
          }
          options={RefundReason}
          variant="resized"
          onChange={(e) =>
            handleChangeSelectInputs(
              props.row.original.saleLineID,
              "reason",
              e.target.value,
            )
          }
          value={
            refundItems.find(
              (item) => item.saleLineID === props.row.original.saleLineID,
            )?.reason || "customer"
          }
          width="7rem"
        />
      ),
    },
    {
      accessorKey: "refund",
      header: "Refund %",
      size: 80,
      cell: (props) => (
        <SelectInput
          disabled={
            !refundItems.some(
              (items) => items.saleLineID === props.row.original.saleLineID,
            )
          }
          options={RefundPercentage}
          variant="resized"
          width="7rem"
          onChange={(e) =>
            handleChangeSelectInputs(
              props.row.original.saleLineID,
              "refundPercentage",
              e.target.value,
            )
          }
          value={
            refundItems.find(
              (item) => item.saleLineID === props.row.original.saleLineID,
            )?.refundPercentage || "100%"
          }
        />
      ),
    },
    {
      accessorKey: "backToStock",
      header: "Back to stock",
      size: 20,
      cell: (props) => (
        <SelectInput
          disabled
          options={RefundStockLocation}
          variant="resized"
          onChange={(e) => {}}
          width="7rem"
        />
      ),
    },
    {
      accessorKey: "condition",
      header: "Condition",
      size: 20,
      cell: (props) => (
        <SelectInput
          disabled={
            !refundItems.some(
              (items) => items.saleLineID === props.row.original.saleLineID,
            )
          }
          options={RefundCondition}
          variant="resized"
          width="7rem"
          onChange={(e) =>
            handleChangeSelectInputs(
              props.row.original.saleLineID,
              "condition",
              e.target.value,
            )
          }
          value={
            refundItems.find(
              (item) => item.saleLineID === props.row.original.saleLineID,
            )?.condition || "new"
          }
        />
      ),
    },
  ];

  const getRefundProducts = async () => {
    const response = await wrapperRequests(
      routesURL.report.orders.getRefundSalesItems(orderDetails.id),
      "GET",
    );

    setInitialRefundItems(response.data);
    setLoading(false);

    const transformArray = response.data.map(
      (sale: RefundOrderInitialValue) => ({
        saleID: sale.saleID,
        saleLines: sale.items.map((item) => ({
          saleLineID: item.saleLineID,
          quantity: item.quantityToRefund,
          reason: "customer",
          condition: "new",
          tenantName: "Incycle",
          shopID: "9",
          refundPercentage: "100%",
        })),
      }),
    );

    setEntireRefundItems(transformArray);
  };

  const reorganizedProducts = initialRefundItems.flatMap(({ saleID, items }) =>
    items.map((item) => ({
      ...item,
      saleID,
    })),
  );

  const addOrRemoveItem = (product: OrderRefundItem) => {
    setRefundItems((prevProducts) => {
      const existingIndex = prevProducts.findIndex(
        (p) => p.saleLineID === product.saleLineID,
      );

      if (existingIndex === -1) {
        return [
          ...prevProducts,
          {
            saleLineID: product.saleLineID,
            saleID: product.saleID,
            quantity: 1,
            reason: "customer",
            condition: "new",
            tenantName: "Incycle",
            shopID: "9",
            refundPercentage: "100%",
          },
        ];
      } else {
        return prevProducts.filter((_, index) => index !== existingIndex);
      }
    });
  };

  const handleChangeQuantity = (
    saleLineID: string,
    type: "increase" | "decrease",
    maxValue: number,
    value: number,
  ) => {
    setRefundItems((prevProducts) =>
      prevProducts.map((product) => {
        if (product.saleLineID === saleLineID) {
          const newQuantity =
            type === "increase"
              ? value < maxValue
                ? value + 1
                : value
              : value > 1
              ? value - 1
              : value;
          return { ...product, quantity: newQuantity };
        }
        return product;
      }),
    );
  };

  const handleChangeSelectInputs = (
    saleLineID: string,
    type: "reason" | "refundPercentage" | "condition",
    newValue: string,
  ) => {
    setRefundItems((prevProducts) =>
      prevProducts.map((product) => {
        if (product.saleLineID === saleLineID) {
          return {
            ...product,
            [type]: newValue,
          };
        }
        return product;
      }),
    );
  };

  const handleChangeSelectInputsEntireRefund = (
    type: "reason" | "condition" | "refundPercentage",
    newValue: string,
  ) => {
    setEntireRefundItems((prevProducts) =>
      prevProducts.map((product) => {
        const updatedSaleLines = product.saleLines.map((saleLine) => {
          return {
            ...saleLine,
            [type]: newValue,
          };
        });

        return {
          ...product,
          saleLines: updatedSaleLines,
        };
      }),
    );
  };

  const handleSubmitRefund = async () => {
    setLoading(true);
    const grouped: { [key: string]: RefundSaleItems[] } = {};

    refundItems.forEach((product) => {
      if (!grouped[product.saleID!]) {
        grouped[product.saleID!] = [];
      }

      grouped[product.saleID!].push({
        saleLineID: product.saleLineID,
        quantity: product.quantity,
        reason: product.reason,
        condition: product.condition,
        tenantName: product.tenantName,
        shopID: product.shopID,
        refundPercentage: product.refundPercentage,
      });
    });

    const reorganizedArray = Object.keys(grouped).map((saleID) => ({
      saleID,
      saleLines: grouped[saleID],
    }));

    const individualRefundBody = {
      sales: reorganizedArray,
    };

    const entireRefundBody = {
      sales: entireRefundItems,
    };

    try {
      await wrapperRequests(routesURL.report.orders.refund, "POST", {
        data: refundType === "entire" ? entireRefundBody : individualRefundBody,
      });

      toastify("success", "Refund completed", "top-center");
      navigate(`/report/orders/${orderId}`);
    } catch (error) {
      toastify("error", "Refund error, try again", "top-center");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRefundProducts();
  }, []);

  return (
    <S.ModalContainer isLoading={loading}>
      {!loading && (
        <>
          <ModalSubTitle>What do you wish to refund?</ModalSubTitle>

          <S.OptionsAlign>
            <CircleCheckbox
              label="Entire order"
              onClick={() => {
                setRefundType("entire");
                setRefundItems([]);
              }}
              checked={refundType === "entire"}
            />

            <CircleCheckbox
              label="Individual items"
              onClick={() => setRefundType("individual")}
              checked={refundType === "individual"}
            />
          </S.OptionsAlign>
        </>
      )}

      {loading && (
        <ReactLoading
          height={25}
          width={25}
          type={"spin"}
          color={newColors["gray-600"]}
        />
      )}

      {refundType === "entire" && !loading && (
        <>
          <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
            Select a reason for refund
          </ModalSubTitle>

          <SelectInput
            options={RefundReason}
            variant="resized"
            onChange={(e) =>
              handleChangeSelectInputsEntireRefund("reason", e.target.value)
            }
            title="Reason"
          />

          <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
            Which stock is the item coming back to?
          </ModalSubTitle>

          <SelectInput
            disabled
            variant="resized"
            options={RefundStockLocation}
            onChange={(e) => {}}
            title="Stock location (Coming soon)"
          />

          <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
            Condition
          </ModalSubTitle>

          <SelectInput
            options={RefundCondition}
            variant="resized"
            onChange={(e) =>
              handleChangeSelectInputsEntireRefund("condition", e.target.value)
            }
            title="Condition"
          />

          <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
            Refund percentage %
          </ModalSubTitle>

          <SelectInput
            options={RefundPercentage}
            variant="resized"
            onChange={(e) =>
              handleChangeSelectInputsEntireRefund(
                "refundPercentage",
                e.target.value,
              )
            }
            title="Refund percentage"
          />
        </>
      )}

      {refundType === "individual" && !loading && (
        <>
          <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
            Items
          </ModalSubTitle>
          <ModalText>Select the items you want to refund</ModalText>

          {!!initialRefundItems.length && (
            <Table
              columns={refundModalColumns}
              columnToFilter="name"
              data={reorganizedProducts}
              hasSort={false}
              style={{ marginTop: "1.5rem", width: "100%" }}
            />
          )}
        </>
      )}

      {!loading && (
        <S.ButtonAlign>
          <Dialog.Close asChild>
            <Button varitant="ghost" title="Cancel" width="8.2rem" />
          </Dialog.Close>
          <Button
            title="Submit Refund"
            width="8.2rem"
            onClick={handleSubmitRefund}
          />
        </S.ButtonAlign>
      )}
    </S.ModalContainer>
  );
}
