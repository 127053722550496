import styled from "styled-components";

export const Container = styled.main`
  flex: 1;
`;

export const Content = styled.div`
  padding: 2rem;
`;

export const PersonalInformation = styled.section`
  max-width: 20rem;
  margin-bottom: 1.5rem;

  & > h2 {
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.SM};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors["black-600"]};
    line-height: ${(props) => props.theme.lineHeight.Default};
  }
`;

export const UserDataContainer = styled.div`
  margin: 1.5rem 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;

  & > p {
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-weight: ${(props) => props.theme.fontWeight.Regular};
    color: ${(props) => props.theme.colors["black-925"]};
    margin-bottom: -0.5rem;
  }
`;

export const LoginSecurity = styled.section`
  max-width: 20rem;
  margin: 1.5rem 0;

  & > h2 {
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.SM};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors["black-600"]};
    line-height: ${(props) => props.theme.lineHeight.Default};
  }
`;

export const UpdatePasswordContainer = styled.div`
  margin-top: 2rem;
  margin: 1.5rem 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;
  
  & > p {
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-weight: ${(props) => props.theme.fontWeight.Regular};
    color: ${(props) => props.theme.colors["black-925"]};
    margin-bottom: -0.5rem;
  }`;

export const WrapperUpdateAt = styled.div`
  margin-top: 3rem;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > button {
    background-color: transparent;
    border: transparent;
    font-size: ${(props) => props.theme.fontSize.XS};
    font-weight: ${(props) => props.theme.fontWeight.Regular};
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    color: ${(props) => props.theme.colors["blue-600"]};
    line-height: ${(props) => props.theme.lineHeight.MD};
    text-decoration: underline;
  }
`;

interface WrapperChangePasswordProps {
  hasError?: boolean; 
}

export const WrapperChangePassword = styled.div<WrapperChangePasswordProps>`
  display: grid;
  gap: 1.5rem;

  margin-top: ${({ hasError }) => (hasError ? "1rem" : "0rem")};

  & > .form_update_pin {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-end;
  }
`;
