import styled, { css } from "styled-components";

export const TableStyled = styled.table`
  width: 100%;
  border-spacing: 0 1rem;
  margin-bottom: 0.5rem;
`;

export const TrStyled = styled.tr`
  box-shadow: ${(props) => props.theme.shadow["Level 1"]};
  border-radius: 8px;
`;

interface ThStyledProps {
  thWidth?: string;
}

export const ThStyled = styled.th<ThStyledProps>`
  background-color: ${(props) => props.theme.newColors["white-300"]};
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  font-family: ${(props) => props.theme.fontFamily.Sora};

  padding: 0 2rem;
  text-align: left;
  line-height: 1rem;

  div.th_align {
    display: flex;
    align-items: center;
    flex-direction: row;

    gap: 0.5rem;

    div {
      display: flex;
      justify-content: center;
      flex-direction: column;

      svg {
        cursor: pointer;
        color: ${(props) => props.theme.colors.black};

        &:hover {
          background-color: ${(props) => props.theme.colors["gray-300"]};
          transition: background-color 0.2s;
        }
      }
    }
  }

  ${(props) =>
    props.thWidth &&
    css`
      max-width: ${props.thWidth};
    `};
`;

interface TdStyledProps {
  tdWidth?: string;
  tdPadding?: string;
  singleButton?: boolean;
}

export const TdStyled = styled.td<TdStyledProps>`
  height: 4rem;
  padding: 1rem 2rem;
  border: 0;

  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors["gray-900"]};
  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-size: ${(props) => props.theme.fontSize.XXXS};

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
    min-width: 160px;
  }

  ${(props) =>
    props.tdWidth &&
    css`
      width: ${props.tdWidth};
    `};

  ${(props) =>
    props.tdPadding &&
    css`
      padding: ${props.tdPadding};
    `};

  ${(props) =>
    props.singleButton &&
    css`
      text-align: center;

      &:last-child {
        min-width: 0px;
      }
    `};
`;

export const ActionsButton = styled.button`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors["gray-400"]};
  border-radius: 8px;

  padding: 0.5rem;
  cursor: pointer;

  & + button {
    margin-left: 8px;
  }

  svg {
    color: ${(props) => props.theme.colors["gray-800"]};
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors["gray-900"]};
    transition: border 0.2s;

    svg {
      color: ${(props) => props.theme.colors["gray-900"]};
      transition: color 0.2s;
    }
  }

  &:disabled {
    border: 1px solid ${(props) => props.theme.colors["gray-400"]};
    cursor: not-allowed;
  }
`;
