import styled, { css } from "styled-components";

interface ModalContainerProps {
  isLoading: boolean;
}

export const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.isLoading &&
    css`
      align-items: center;
      justify-content: center;
    `};
`;

export const OptionsAlign = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
`;

export const ButtonAlign = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1.5rem;
`;

export const CheckBoxRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
