import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonAlign = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1.5rem;
`;
