import { Button } from "../../../../../../components/New/Button";
import { ModalSubTitle } from "../../../../../../components/New/Modal/styles";
import { SelectInput } from "../../../../../../components/SelectInput";
import { monthsOptions } from "../../../AccountingSalesReport/monthsOptions";
import * as S from "./styles";

export function CancelModal() {
  return (
    <S.ModalContainer>
      <ModalSubTitle style={{ marginBottom: 16 }}>
        Select a reason for cancellation
      </ModalSubTitle>

      <SelectInput
        options={monthsOptions}
        variant="resized"
        onChange={(e) => {}}
        title="Reason"
      />

      <ModalSubTitle style={{ marginTop: 24, marginBottom: 16 }}>
        Which stock is the item coming back to?
      </ModalSubTitle>

      <SelectInput
        options={monthsOptions}
        variant="resized"
        onChange={(e) => {}}
        title="Stock location (Coming soon)"
      />

      <S.ButtonAlign>
        <Button varitant="ghost" title="Cancel" width="8.2rem" />
        <Button title="Submit Cancellation" width="10.5rem" />
      </S.ButtonAlign>
    </S.ModalContainer>
  );
}
