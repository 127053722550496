export const RefundReason = [
  {
    id: "1",
    label: "Customer",
    value: "customer",
  },
  {
    id: "2",
    label: "Damage",
    value: "damage",
  },
  {
    id: "3",
    label: "Restock",
    value: "restock",
  },
  {
    id: "4",
    label: "Other",
    value: "other",
  },
];

export const RefundStockLocation = [
  {
    id: "1",
    label: "Pomona",
    value: "",
  },
];

export const RefundCondition = [
  {
    id: "1",
    label: "New",
    value: "new",
  },
  {
    id: "2",
    label: "Used",
    value: "used",
  },
  {
    id: "3",
    label: "New Other",
    value: "new-other",
  },
];

export const RefundPercentage = [
  {
    id: "1",
    label: "100%",
    value: "100%",
  },
  {
    id: "2",
    label: "90%",
    value: "90%",
  },
  {
    id: "3",
    label: "80%",
    value: "80%",
  },
  {
    id: "4",
    label: "70%",
    value: "70%",
  },
  {
    id: "5",
    label: "60%",
    value: "60%",
  },
  {
    id: "6",
    label: "50%",
    value: "50%",
  },
];
