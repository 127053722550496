import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useContextSelector } from "use-context-selector";
import { SearchInput } from "../../../../../../components/SearchInput";
import { SelectInput } from "../../../../../../components/SelectInput";
import {
  TableComponent,
  TdComponent,
  ThComponent,
} from "../../../../../../components/TableElements";
import { StoresContext } from "../../../../../../contexts/StoresContext";

import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { SearchData, StoreListTypes } from "../../../../../../@types/Stores";
import * as S from "./styles";

interface WebSelectStoreProps {
  stores: StoreListTypes[];
  storesGranted: string[];
  setStoresGranted: React.Dispatch<React.SetStateAction<string[]>>;
  userDefaultStore: string;
  setUserDefaultStore: React.Dispatch<React.SetStateAction<string>>;
  error: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  storesLoading: boolean;
}

export function WebSelectStore({
  stores,
  storesGranted,
  setStoresGranted,
  userDefaultStore,
  setUserDefaultStore,
  storesLoading,
}: WebSelectStoreProps) {
  const [selectAll, setSelectAll] = useState(false);

  const [storeSearchData, setStoreSearchData] = useState<SearchData>({
    shopName: null,
    type: null,
    tenantName: null,
    page: 1,
  });

  const {
    allTenants,
    fetchAllTenants,
    fetchAllStoresAllTenants,
    allStoresFilterBy,
  } = useContextSelector(StoresContext, (context) => {
    return context;
  });

  function handleSelectAll() {
    if (storesGranted.length !== 0) {
      setStoresGranted([]);
      setSelectAll(false);
    } else {
      setStoresGranted(
        stores.map(({ id }) => {
          return id;
        }),
      );
      setSelectAll(true);
    }
  }

  function handleCheckBoxChange(code: string) {
    const updatedStoresGranted = storesGranted.includes(code)
      ? storesGranted.filter((item) => item !== code)
      : [...storesGranted, code];

    const defaultStoreWithStoreAccess =
      updatedStoresGranted.includes(userDefaultStore);

    if (!defaultStoreWithStoreAccess && storesGranted.length === 0) {
      setUserDefaultStore(code);
    } else if (!defaultStoreWithStoreAccess) {
      setUserDefaultStore("");
    }

    setStoresGranted(updatedStoresGranted);
  }

  function handleSelectDefaultShop(code: string) {
    if (!storesGranted.includes(code)) {
      setStoresGranted([...storesGranted, code]);
      return setUserDefaultStore(code);
    }

    return setUserDefaultStore(code);
  }

  const handleSendSearchParams = async (
    searchType: keyof SearchData,
    event: React.ChangeEvent<HTMLElement>, 
  ) => {
    event.preventDefault();
    setStoreSearchData((state) => ({
      ...state,
      [searchType]: (event.target as HTMLSelectElement).value, 
    }));
  };
  
  

  async function handleFetchAllStores() {
    await fetchAllStoresAllTenants(storeSearchData);
  }

  useEffect(() => {
    setStoresGranted(storesGranted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storesGranted]);

  useEffect(() => {
    fetchAllTenants();
  }, []);

  return (
    <S.SelectStoreContainer>
      <S.SelectStoreHeader>
        <div className="section_align">
          <h3>Stores</h3>
        </div>
        <span>Select below the stores that this user can access</span>

        <S.SelectStoreFiltersContainer>
          <S.SelectAllContainer
            disabled
            type="button"
            onClick={() => handleSelectAll()}
          >
            <input
              disabled
              type="checkbox"
              checked={selectAll || storesGranted.length === stores.length}
              onChange={() => handleSelectAll()}
            />
            <p>Select All</p>
          </S.SelectAllContainer>

          <SelectInput
            onChange={() => {}}
            variant="selectStores"
            placeholder="Select Type"
            options={[
              { id: "1", value: "warehouse", label: "Warehouse" },
              { id: "2", value: "store", label: "Store" },
            ]}
          />

          <SelectInput
            variant="selectStores"
            placeholder="Select Region"
            options={allTenants.map((tenantName) => ({
              id: tenantName.id,
              value: tenantName.name,
              label: tenantName.name,
            }))}
            onChange={(event) => {
              allStoresFilterBy("tenantName", event.target.value);
              handleSendSearchParams("tenantName", event); 
            }}
          />

          <SearchInput
            placeholder={"Store name"}
            variant="form"
            onChange={(event) => {
              allStoresFilterBy("name", event.target.value);
              handleSendSearchParams("shopName", event);  
            }}
            onPressEnter={() => handleFetchAllStores()}
          />
        </S.SelectStoreFiltersContainer>
      </S.SelectStoreHeader>

      {storesLoading ? (
        <S.StoresLoadingContainer>
          <ReactLoading height={35} width={35} type={"spin"} color="#000" />
        </S.StoresLoadingContainer>
      ) : (
        <TableComponent>
          <thead>
            <tr>
              <ThComponent withArrows={false} />
              <ThComponent>Store Name</ThComponent>
              <ThComponent>Store type</ThComponent>
              <ThComponent>Status</ThComponent>
              <ThComponent>Region</ThComponent>
              <ThComponent>State</ThComponent>
              <ThComponent>City</ThComponent>
              <ThComponent>Default Store</ThComponent>
            </tr>
          </thead>

          <tbody>
            {stores &&
              stores.map((store) => (
                <tr key={store.id} className="stores">
                  <TdComponent>
                    <input
                      type="checkbox"
                      checked={storesGranted.includes(store.id)}
                      onChange={() => handleCheckBoxChange(store.id)}
                      style={{
                        height: "16px",
                        width: "16px",
                        cursor: "pointer",
                      }}
                    />
                  </TdComponent>
                  <TdComponent tdWidth={"30%"}>{store?.name}</TdComponent>
                  <TdComponent tdWidth={"30%"}>
                    <S.StoreTypeContainer>Warehouse</S.StoreTypeContainer>
                  </TdComponent>

                  <TdComponent>
                    <S.StoreStatusContainer>Active</S.StoreStatusContainer>
                  </TdComponent>
                  <TdComponent>{store?.tenantName}</TdComponent>

                  <TdComponent>{store.state}</TdComponent>
                  <TdComponent>{store.city}</TdComponent>
                  <TdComponent>
                    <input
                      type="checkbox"
                      checked={userDefaultStore === store.id}
                      onChange={() => handleSelectDefaultShop(store.id)}
                      style={{
                        height: "16px",
                        width: "16px",
                        cursor: "pointer",
                      }}
                    />
                  </TdComponent>
                </tr>
              ))}
          </tbody>
        </TableComponent>
      )}
    </S.SelectStoreContainer>
  );
}
