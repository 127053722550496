import { ButtonHTMLAttributes } from "react";
import * as S from "./styles";

interface TabelCheckboxProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  checked: boolean;
}

export function TableCheckbox({ checked, ...rest }: TabelCheckboxProps) {
  return (
    <S.Container {...rest}>{checked && <S.CheckIcon size={24} />}</S.Container>
  );
}
