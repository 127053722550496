import styled from "styled-components";
import { IoMdAdd, IoMdRemove } from "react-icons/io";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.newColors.white};

  height: 2rem;
  width: 6rem;

  border: 1px solid ${(props) => props.theme.colors["gray-100"]};
  border-radius: 0.5rem;

  color: ${(props) => props.theme.newColors["gray-900"]};

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0.35rem;

    border: none;
    background-color: transparent;
    color: ${(props) => props.theme.newColors["gray-900"]};
  }
`;
