import React from "react";
import * as S from "./styles";

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  title?: string;
  errorMessage?: string;
  titleGap?: string; 
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ errorMessage, title, titleGap = "0.75rem", ...rest }, ref) => {
    return (
      <S.Container titleGap={titleGap}>
        {title && <S.Title>{title}</S.Title>}
        <S.InputContent>
          <input ref={ref} {...rest} />
        </S.InputContent>
        {!!errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
      </S.Container>
    );
  }
);
