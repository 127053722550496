import styled from "styled-components";
import { BsCheck } from "react-icons/bs";

export const Container = styled.button`
  display: flex;
  min-width: 1.5rem;
  min-height: 1.5rem;
  max-height: 1.5rem;
  justify-content: center;
  align-items: center;

  border: 2px solid ${(props) => props.theme.newColors["gray-900"]};
  border-radius: 0.5rem;
  background-color: transparent;
`;

export const CheckIcon = styled(BsCheck)`
  color: ${(props) => props.theme.newColors["gray-900"]};
`;
