import styled from "styled-components";

export const CheckboxContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  gap: 0.75rem;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  border: 2px solid ${(props) => props.theme.newColors["gray-900"]};
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;

  &::after {
    content: "";
    width: 0.55rem;
    height: 0.55rem;
    background-color: ${(props) => props.theme.newColors["gray-900"]};
    border-radius: 50%;
    opacity: 0;
    transition: all 0.3s ease;
  }

  ${HiddenCheckbox}:checked + &::after {
    opacity: 1;
  }
`;

export const CircleCheckboxLabel = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  color: ${(props) => props.theme.newColors["gray-900"]};
`;
