import { IoMdAdd, IoMdRemove } from "react-icons/io";
import * as S from "./styles";
import { ButtonHTMLAttributes } from "react";

interface IncreaseInputProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  value: string | number;
  maxValue?: string | number;
  handleIncreaseValue: () => void;
  handleDecreaseValue: () => void;
}

export function IncreaseInput({
  handleIncreaseValue,
  handleDecreaseValue,
  maxValue,
  value,
  ...rest
}: IncreaseInputProps) {
  return (
    <S.Container>
      <button onClick={handleDecreaseValue} {...rest}>
        <IoMdRemove size={20} />
      </button>

      <span>
        {value}
        {maxValue && `/${maxValue}`}
      </span>

      <button onClick={handleIncreaseValue} {...rest}>
        <IoMdAdd size={20} />
      </button>
    </S.Container>
  );
}
